import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

export interface StoreI<T> {
  state$: Observable<T[] | null>;
  state: T[] | null;

  init(initialState: T[] | null): void;

  loadItems(): void;

  setState(nextState: T[] | null): void;

  addToStore(item: T): void;

  updateStore(item: T): void;

  removeFromStore(id: number): void;

  destroy(): void;

  reloadStore(): void;
}

export interface BaseStoreItem {
  id?: number | string;
}

export abstract class Store<T extends BaseStoreItem> implements StoreI<T> {

  state$: Observable<T[] | null>;
  private stateStore$: BehaviorSubject<T[] | null>;
  private reloadStore$: Subject<void>;
  protected subscription: Subscription;

  protected constructor() {
  }

  init(initialState: T[] | null = null): void {
    this.stateStore$ = new BehaviorSubject(initialState);
    this.state$ = this.stateStore$.asObservable();
    this.subscription = new Subscription();
    this.reloadStore$ = new Subject();
  }

  destroy(): void {
    this.stateStore$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  abstract loadItems(): void;

  get state(): T[] | null {
    return this.stateStore$.value;
  }

  addToStore(item: T): void {
    const state = this.state;
    this.setState(state ? [...state, item] : [item]);
  }

  updateStore(item: T): void {
    const state = this.state;
    if (Array.isArray(state)) {
      const itemIndex = state.findIndex(i => i.id === item.id);
      if (itemIndex > -1) {
        state.splice(itemIndex, 1, item);
        this.setState([...state]);
      }
      return;
    }
    this.setState([item]);
  }

  setState(nextState: T[] | null): void {
    this.stateStore$.next(nextState);
  }

  removeFromStore(id: number): void {
    const state = this.state;
    if (!Array.isArray(state)) {
      return;
    }

    const itemIndex = state.findIndex(item => item.id === id);
    if (itemIndex < -1) {
      return;
    }
    state.splice(itemIndex, 1);
    this.setState([...state]);
  }

  reloadStore(): void {

  }
}
